import logo from './logo.svg';
import './App.css';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase.js";
import reactLogo from "./assets/img/auralogo.svg";
import reactText from "./assets/img/text.svg";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'
import React, { useState, useEffect } from 'react';
import { getAdditionalUserInfo, reauthenticateWithCredential } from "firebase/auth"


function App() {

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app)
  const db = getFirestore(app);

  const [boolLogin, setBoolLogin] = useState(true);
  const [boolLogout, setBoolLogout] = useState(false);
  const provider = new GoogleAuthProvider()
  const [isOpened, setIsOpened] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [showUserNotExist, setShowUserNotExist] = useState(false);
  const name0 = useState('You must log in to delete your account and data.This will permanently delete all of your information from our servers!');
  const name1 = useState('This will permanently delete all of your account and data from our servers! Are you sure you want to delete your account?');
  const [name, setName] = useState('');
  const [deletSuccess, setDeletSuccess] = useState(false);
  const logo = require('./assets/img/auralogo.svg');




  async function deleteAccount() {
    const uid = auth.currentUser.uid; // 👈
    auth.currentUser.delete().then(() => {
      db.collection('users')
        .doc(uid) // 👈
        .delete()
    }).catch((error) => {
      console.log(error, "error")
    }).finally(() => setDeletSuccess(true))
  }

  const signOutWithGoogle = () => {
    signOut(auth, provider);
    setBoolLogout(false)
    setBoolLogin(true)
    setUserExist(false)
    setDeletSuccess(false)
    setShowUserNotExist(false)
    setName("")

  };

  const signInWithGoogle = async () => {
    setUserExist(false)
    signInWithPopup(auth, provider).then((result) => {
      console.log(result);
      const name = result.user.displayName
      const email = result.user.email
      const profilePic = result.user.photoURL
      const accountUid = result.user.uid
      setName("Hi " + result.user.displayName)
      setBoolLogout(true)
      setBoolLogin(false)
      const { isNewUser } = getAdditionalUserInfo(result)
      if (isNewUser) {

        console.log('isNewUser')
        // user has signed in first time
        // setDoc(...)
        setShowUserNotExist(true)
        setUserExist(false)

      } else {
        setUserExist(true)

        console.log('isNotNewUser')
        // Existing user, document created already. 
      }








    }).catch((error) => {
      console.error();
    })
  }
  return (
    <div className="mainDiv">
      <div className="RowDiv1">
        <center><img className="img" src={reactLogo} /></center>
        <center><img className="txt" src={reactText} /></center>
        {boolLogin && <center><div className="div"><span className="span">{name0}</span></div></center>}
        <center><div className="div"><span className="span2">{name}</span></div></center>
        {userExist && <center><div className="div"><span className="span">{name1}</span></div></center>}
        <center>{showUserNotExist && <h1>The user does not exist</h1>}</center>
        <center>{deletSuccess && <h1>The user is deleted successfully</h1>}</center>
        <center>{boolLogin && <button onClick={signInWithGoogle} className="btn"></button>}</center>
        <center>{userExist && !deletSuccess && <button onClick={deleteAccount} className="btn3"><h1> Yes! Delete my account</h1></button>}</center>
        <center>{boolLogout && <button className="btn2" onClick={signOutWithGoogle}><h1>Sign out</h1></button>}</center>
      </div>
      <div className="RowDiv2"></div>
    </div>
  );
}

export default App;
